import React from "react"
import Layout from "@components/layout";

// Import LInk
// import pdfDl1  from  '@MMT&C202200804VER4.1.pdf';

// Import SVG
import dl_icon from '@images/dl_icon.svg';

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/legal-documents.json";
import langJa from "@content/locales/ja/legal-documents.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const LegalDocuments = ( props ) => {
  
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // 構造化データ用配列
  // 作成・更新日
  const date = {modified: process.env.MODIFIED};
  
  // Seo情報設定
  const pathName = 'legal-documents'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, date);

  // 言語別URL設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';
  
  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  
  // 使用時に戻す
  // const platDl1 = " https://portal.miltonmarkets.com/ja/downloads";
  // const platDl2 = " https://trade.mql5.com/trade?servers=MiltonTech-Platform&lang=ja";
  // const lgcLink1 = " http://www.allaboutcookies.org/";
  // const lgcLink2 = " http://www.networkadvertising.org/";  
  const lgcLink3 = [langUrl + "privacypolicy/"];
  const lgcLink4 = [langUrl + "cookiepolicy"];
  const lgcLink5 = [langUrl + "termsofuse/"];
  // const url4 = [langUrl + "mt4-desktop-win/",langUrl + "mt4-desktop-mac/",langUrl + "mt4-mobile/",langUrl + "mt4-web-browser/"];

  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 md:flex">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["lgc-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["lgc-h1-txt"]}{postLang["lgc-h1-txt_2"]}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-xl mx-auto md:pt-11 pt-5 md:pb-11 pb-10 md:max-w-5xl px-6 mb-5">
        <div className="text-left md:mt-7 mt-5 mb-10">
          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px]">{postLang["lgc-h2"]}</h2>
        </div>
        <div className="flex flex-wrap flex-auto md:max-5w-xl justify-center md:justify-start">
          <div className="md:mr-5 ml-0 bg-[#F7F7F7] rounded-lg md:max-w-[290px] w-full p-5 mb-5 text-base text-black hover:text-black/50">
            <a href={lgcLink3} aria-label="Mute volume"><p><img src={dl_icon} className="inline-block -rotate-90" alt="" /><span className="pl-3 pt-3">{postLang["lgc-btn1"]}</span></p></a>
          </div>
          <div className="bg-[#F7F7F7] rounded-lg md:max-w-[290px] w-full p-5 mb-5 text-base text-black hover:text-black/50">
            <a href={lgcLink4} aria-label="Mute volume"><p><img src={dl_icon} className="inline-block -rotate-90" alt="" /><span className="pl-3 pt-3">{postLang["lgc-btn2"]}</span></p></a>
          </div>
        </div>
        <div className="text-left md:mt-7 mt-10 mb-10">
          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px]">{postLang["lgc-h2_2"]}</h2>
        </div>
        <div className="flex flex-wrap md:max-5w-xl justify-center md:justify-start">
          <div className="md:mr-5 ml-0 bg-[#F7F7F7] rounded-lg md:max-w-[290px]  w-full p-5 mb-5  text-base text-black hover:text-black/50">
            <a href={lgcLink5} aria-label="Mute volume"><p><img src={dl_icon} className="inline-block -rotate-90" alt="" /><span className="pl-3 pt-3">{postLang["lgc-btn3"]}</span></p></a>
          </div>
        </div>
        <div className="text-left md:mt-7 mt-10 mb-10">
          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px]">{postLang["lgc-h2_3"]}</h2>
        </div>
        <p className="md:mb-[70px] mb-2">{postLang["lgc-txt_1"]}<br></br>{postLang["lgc-txt_2"]}</p>
          {/* <p className="mb-[70px]"><a href={pdfDl1} className="underline hover:no-underline">Terms and Conditions(DL here)</a></p>*/}
      </div>

      <div className="Feature  bg-fixed">
      <div className="mt-11 py-14 py-14  md:py-10 md:py-10"> 
        <div className="md:mx-auto max-w-5xl flex justify-center"><a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a> </div>
      </div></div>
    </Layout>
  </>
  )
}
export default LegalDocuments